/*
	xs <  576px
	sm >= 576px
	md >= 768px
	lg >= 992px
	xl >= 1200px
*/

.xs-mf(@rules) {
	@media only screen and (max-width: 576px) { @rules(); }
}

.sm-mf(@rules) {
	@media only screen and (min-width: 576px) { @rules(); }
}

.md-mf(@rules) {
	@media only screen and (min-width: 768px) { @rules(); }
}

.lg-mf(@rules) {
	@media only screen and (min-width: 992px) { @rules(); }
}

.xl-mf(@rules) {
	@media only screen and (min-width: 1200px) { @rules(); }
}

// desktop first

.xs(@rules) {
	@media (max-width: 575.98px) { @rules(); }
}

.sm(@rules) {
	@media (max-width: 767.98px) { @rules(); }
}

.md(@rules) {
	@media (max-width: 991.98px) { @rules(); }
}

.lg(@rules) {
	@media (max-width: 1199.98px) { @rules(); }
}

/*
RETINA (2x RESOLUTION DEVICES)
This applies to the retina iPhone (4s) and iPad (2,3) along with
other displays with a 2x resolution. You can also create a media
query for retina AND a certain size if you want. Go Nuts.
*/
.bp-retina(@rules) {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	       only screen and (min--moz-device-pixel-ratio: 1.5),
	       only screen and (min-device-pixel-ratio: 1.5) { @rules(); }
}

/*
PRINT STYLESHEET
Feel free to customize this. Remember to add things that won't make
sense to print at the bottom. Things like nav, ads, and forms should
be set to display none.
*/
.bp-print(@rules) {
	@media print { @rules(); }
}


