@import (reference) "_helpers.less";
@import (reference) "_helpers-forex.less";
@import (reference) "_breakpoint.less";

.error-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__inner {
        margin: 20px;
        max-width: 550px;
    }

    &__image {
        width: 100%;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 20px;

        .md({
            font-size: 36px;
            line-height: 42px;
        });
    }

    &__desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 40px;

        .md({
            font-size: 16px;
            line-height: 28px;
        });
    }
}
